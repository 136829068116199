@font-face {
	font-family: 'SF-Bold';
	src: local('SFPRODISPLAYBOLD'),
		url('./fonts/sf-pro-display/SFPRODISPLAYBOLD.OTF') format('truetype');
}

@font-face {
	font-family: 'SF-Medium';
	src: local('SFPRODISPLAYMEDIUM'),
		url('./fonts/sf-pro-display/SFPRODISPLAYMEDIUM.OTF') format('truetype');
}

@font-face {
	font-family: 'SF-Regular';
	src: local('SFPRODISPLAYREGULAR'),
		url('./fonts/sf-pro-display/SFPRODISPLAYREGULAR.OTF') format('truetype');
}

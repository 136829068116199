@keyframes animateFadeIn {
	0% {
		opacity: 0;
		transform: translateY(100px);
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

.animateFadeIn {
	-webkit-animation-name: animateFadeIn;
	animation-name: animateFadeIn;
}
